const types = {
  403: {
    img: require("@/assets/svg/403.svg"),
    title: "403",
    desc: "抱歉，你无权访问该页面",
  },
  404: {
    img: require("@/assets/svg/404.svg"),
    title: "404",
    desc: "抱歉，你访问的页面不存在",
  },
  500: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg",
    title: "500",
    desc: "抱歉，服务器出错了",
  },
};

export default types;
